import React from 'react';
import Layout from '../../layout/Layout/Layout';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import * as styles from './not-found.module.scss';


export default function NotFound() {
  return (
    <Layout hasFooter={ false }>
      <div className={ styles.notFound }>
        <h1 className={ styles.notFoundTitle }>
          <p>404</p>
          <p>Not Found</p>
        </h1>

        <LinkButton to="/">Back to Home</LinkButton>
      </div>
    </Layout>
  );
}